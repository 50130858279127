import Link from 'next/link'
import type { Voice } from '@/types/newt/voice'
import { useEffect, useState } from 'react'

export function VoiceList({ voice }: { voice: Voice }) {
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 200)

    return () => clearTimeout(timer)
  })
  return (
    <li className={`${isVisible ? '' : 'hidden opacity-0'}`}>
      <Link
        className="flex flex-col gap-y-[0.5rem] justify-between w-full h-full bg-white rounded-lg border border-gray shadow-[0_4px_24px_rgba(199,189,168,.08)] overflow-hidden group/item"
        href={`/voice/${voice.slug}`}
      >
        <div>
          <div className="aspect-video overflow-hidden">
            <img
              className="w-full h-full object-cover transition-transform group-hover/item:scale-110 duration-500 ease-in-out will-change-transform"
              src={voice.eyecatch.src}
              alt={voice.title}
            />
          </div>
          <h3 className="text-base font-bold leading-[1.7] mt-3 px-5">
            {voice.catchPhrase}
          </h3>
        </div>
        <div className="px-5">
          <p className="text-sm leading-[1.7]">{voice.title}</p>
          <ul className="inline-flex flex-wrap gap-[4px] mt-2 pt-2 pb-4 border-t border-dashed border-gray w-full">
            {Array.isArray(voice.industry)
              ? voice.industry.map(
                  (industry) =>
                    industry.name && (
                      <li
                        key={industry._id}
                        className="text-sm sm:text-xs text-secondary leading-[1.7] bg-[#E6F2FD] px-[0.5rem] py-[0.25rem] rounded-[4px]"
                      >
                        {industry.name}
                      </li>
                    )
                )
              : voice.industry.name && (
                  <li
                    key={voice.industry._id}
                    className="text-sm sm:text-xs text-secondary leading-[1.7] bg-[#E6F2FD] px-[0.5rem] py-[0.25rem] rounded-[4px]"
                  >
                    {voice.industry.name}
                  </li>
                )}

            {Array.isArray(voice.department)
              ? voice.department.map(
                  (department) =>
                    department.name && (
                      <li
                        key={department._id}
                        className="text-sm sm:text-xs text-secondary leading-[1.7] bg-[#E6F2FD] px-[0.5rem] py-[0.25rem] rounded-[4px]"
                      >
                        {department.name}
                      </li>
                    )
                )
              : voice.department.name && (
                  <li
                    key={voice.department._id}
                    className="text-sm sm:text-xs text-secondary leading-[1.7] bg-[#E6F2FD] px-[0.5rem] py-[0.25rem] rounded-[4px]"
                  >
                    {voice.department.name}
                  </li>
                )}

            {voice.scale.name && (
              <li className="text-sm sm:text-xs text-secondary leading-[1.7] bg-[#E6F2FD] px-[0.5rem] py-[0.25rem] rounded-[4px]">
                {voice.scale.name}
              </li>
            )}

            {Array.isArray(voice.documentType)
              ? voice.documentType.map(
                  (documentType) =>
                    documentType.name && (
                      <li
                        key={documentType._id}
                        className="text-sm sm:text-xs text-secondary leading-[1.7] bg-[#E6F2FD] px-[0.5rem] py-[0.25rem] rounded-[4px]"
                      >
                        {documentType.name}
                      </li>
                    )
                )
              : voice.documentType.name && (
                  <li
                    key={voice.documentType._id}
                    className="text-sm sm:text-xs text-secondary leading-[1.7] bg-[#E6F2FD] px-[0.5rem] py-[0.25rem] rounded-[4px]"
                  >
                    {voice.documentType.name}
                  </li>
                )}
          </ul>
        </div>
      </Link>
    </li>
  )
}
