import React from 'react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import { MainLogos } from './MainLogos'
import { getLogosList, newtClient } from '@/utils/newt/newt'
import { Logos } from '@/types/newt/logos'
import { useCLickEvent } from '@/hooks/use-tracker'

const PointMessage = () => (
  <p className="flex justify-center">
    <picture>
      <source
        srcSet="/images/img_main_point_message_sp.svg"
        media="(max-width: 860px)"
      />
      <img
        decoding="async"
        src="/images/img_main_point_message.svg"
        alt="サポート満足度99%以上 業務削減効果実感94%以上"
      />
    </picture>
  </p>
)

export const Visual = ({
  logos,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { pushGa4ClickEvent } = useCLickEvent()
  return (
    <section className="mt-12 maxsm:mt-10">
      <div className="text-center maxsm:px-1 relative w-[1280px] mx-auto maxonlyxl:w-full maxsm:w-full">
        <div className="main_hero">
          <h1 className="title text-center text-textblack text-4xlh maxsm:text-2xl font-bold">
            採用するなら、
            <br />
            追加人員よりLAWGUE
          </h1>
          <div className="relative">
            <p className="lead text-center text-lg maxsm:text-base leading-8 maxsm:leading-7 mt-3">
              クラウドドキュメント
              <br className="hidden maxsm:block" />
              ワークスペース「LAWGUE」で
              <br />
              契約書・規程・開示文書など
              <br />
              様々な文書の作成・検索・レ
              <br className="hidden maxsm:block" />
              ビューを効率化
            </p>
            <img
              decoding="async"
              src="/images/img_illust03.svg"
              alt=""
              className="img_illust03"
            />
          </div>
          <div className="maxsm:hidden">
            <PointMessage />
          </div>
          <div className="mt-5 maxsm:mt-4">
            <a
              href="/request"
              className="base_button linkbutton__xlarge button__visible button__nonefull transition hover:bg-secondary hover:border-secondary"
              onClick={() => {
                pushGa4ClickEvent({
                  eventCategory: 'request',
                  eventUi: 'top-main-visual',
                })
              }}
            >
              資料をダウンロード
            </a>
          </div>
        </div>
        <div className="text-right mt-7 text-[10px] w-[1030px] mx-auto maxonlyxl:w-[80%] maxsm:hidden block">
          <p>※2021年12月～2022年1月 LAWGUEユーザーアンケート</p>
        </div>
        <div className="grid grid-cols-4 lg:grid-cols-8 gap-2 justify-between mt-5 mb-2 px-6 maxsm:mt-6 w-[1280px] mx-auto maxonlyxl:w-full maxsm:w-full maxsm:px-2">
          {logos &&
            logos.map((logos: Logos) => (
              <MainLogos key={logos._id} logos={logos} />
            ))}
        </div>
        <div className="sm:hidden">
          <PointMessage />
        </div>
        <div className="text-center text-[10px] mx-auto maxonlyxl:w-[80%] maxsm:block hidden">
          <p>※2021年12月～2022年1月 LAWGUEユーザーアンケート</p>
        </div>
        <div className="w-[690px] maxsm:w-full mx-auto relative">
          <img
            decoding="async"
            src="/images/img_main_hero.png"
            alt=""
            className="maxsm:w-10/12 maxsm:mx-auto"
          />
          <img
            decoding="async"
            src="/images/img_illust01.svg"
            alt=""
            className="img_illust01_top"
          />
        </div>
        <picture>
          <source srcSet="/images/img_main_bg.svg" media="(min-width: 860px)" />
          <img
            decoding="async"
            src="/images/img_main_bg.svg"
            alt=""
            className="image_main_bg"
          />
        </picture>
      </div>
    </section>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const [{ logos }] = await Promise.all([
    getLogosList({
      client: newtClient('cdn'),
      query: { order: ['-_sys.customOrder'] },
    }),
  ])

  return {
    props: {
      logos,
    },
  }
}

export default Visual
