import React, { memo } from 'react'
import Link from 'next/link'

type LinkButtonProps = {
  href?: string
  size?: 'small' | 'medium' | 'large' | 'xlarge'
  bg?: 'visible' | 'hidden'
  full?: 'full' | 'nonefull'
  border?: 'none' | ''
  more?: boolean
  label: string
  blank?: boolean
  onClick?: () => void
}

const sizeswitch = (size: 'small' | 'medium' | 'large' | 'xlarge', more) => {
  let button = ''

  switch (size) {
    case 'medium':
      button = `linkbutton__medium ${more === true ? 'button__more' : ''}`
      break
    case 'small':
      button = 'linkbutton__small'
      break
    case 'large':
      button = 'linkbutton__large'
      break
    case 'xlarge':
      button = 'linkbutton__xlarge'
      break
    default:
      break
  }

  return button
}

const bgswitch = (bg: 'visible' | 'hidden') => {
  let button = ''
  switch (bg) {
    case 'visible':
      button = 'button__visible'
      break
    case 'hidden':
      button = 'button__hidden'
      break
    default:
      break
  }

  return button
}

const fullswitch = (full: 'full' | 'nonefull') => {
  let button = ''
  switch (full) {
    case 'full':
      button = 'button__full'
      break
    case 'nonefull':
      button = 'button__nonefull'
      break
    default:
      break
  }

  return button
}

export const LinkButton: React.FC<LinkButtonProps> = memo(
  ({
    href = '/',
    size = 'medium',
    bg = 'visible',
    full = 'nonefull',
    border = '',
    more = false,
    blank = false,
    label,
    onClick,
  }: {
    href?: string
    size?: 'small' | 'medium' | 'large' | 'xlarge'
    bg?: 'visible' | 'hidden'
    full?: 'full' | 'nonefull'
    border?: string
    more?: boolean
    blank?: boolean
    label?: string
    onClick?: () => void
  }) => {
    return (
      <>
        {blank === true ? (
          <a
            href={href}
            className={`base_button ${sizeswitch(size, more)} ${bgswitch(
              bg
            )}  ${border && 'button__none'} ${fullswitch(full)} transition ${
              bg === 'hidden'
                ? 'hover:text-secondary hover:border-secondary'
                : 'hover:bg-secondary hover:border-secondary'
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {label}
          </a>
        ) : (
          <>
            {href.indexOf('#') === 0 ? (
              <a
                href={href}
                className={`pagescroll base_button ${sizeswitch(
                  size,
                  more
                )} ${bgswitch(bg)}  ${border && 'button__none'} ${fullswitch(
                  full
                )} transition ${
                  bg === 'hidden'
                    ? 'hover:text-secondary hover:border-secondary'
                    : 'hover:bg-secondary hover:border-secondary'
                }`}
              >
                {label}
              </a>
            ) : (
              <Link href={href} legacyBehavior>
                <a
                  className={`base_button ${sizeswitch(size, more)} ${bgswitch(
                    bg
                  )}  ${border && 'button__none'} ${fullswitch(
                    full
                  )} transition ${
                    bg === 'hidden'
                      ? 'hover:text-secondary hover:border-secondary'
                      : 'hover:bg-secondary hover:border-secondary'
                  }`}
                  onClick={() => {
                    onClick?.()
                  }}
                >
                  {label}
                </a>
              </Link>
            )}
          </>
        )}
      </>
    )
  }
)

LinkButton.displayName = 'LinkButton'
