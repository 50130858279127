import { createClient, GetContentsQuery } from 'newt-client-js'
import type { News } from '../../types/newt/news'
import type { Column, ColumnCategory } from '../../types/newt/column'
import type { Seminar } from '../../types/newt/seminar'
import type { Service } from '../../types/newt/service'
import { Logo } from '@/types/newt/logo'
import { Logos } from '@/types/newt/logos'
import {
  Departments,
  DocumentTypes,
  Industries,
  Scales,
  Voice,
} from '@/types/newt/voice'
import { Department } from '@/types/newt/department'
import { Opportunity } from '@/types/newt/opportunity'

// import { buildMemoryStorage, setupCache } from 'axios-cache-interceptor'
// import Axios from 'axios'

// const cache = Axios.defaults.cache
//   ? Axios
//   : setupCache(Axios.create(), {
//       storage: buildMemoryStorage(false, 60 * 1000, false),
//     })

export const newtClient = (
  apiType: 'cdn' | 'api' = 'cdn',
  token: string = process.env.NEWT_FRAIM_CDN_TOKEN ?? ''
) =>
  createClient({
    apiType,
    spaceUid: 'fraim',
    token,
    // adapter: Axios.getAdapter(cache),
  })

export type NewtClient = ReturnType<typeof newtClient>

// const newtSsrClient = newtClient('cdn')

export const getNewsList = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ news: News[]; total: number }> => {
  const { items: news, total } = await client.getContents<News>({
    appUid: 'lawgue',
    modelUid: 'news',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    news,
    total,
  }
}

export const getNewsItem = async ({
  client,
  slug,
}: {
  client: NewtClient
  slug: string
}): Promise<News | null> => {
  if (!slug) return null

  const news = await client.getFirstContent<News>({
    appUid: 'lawgue',
    modelUid: 'news',
    query: {
      depth: 2,
      slug,
    },
  })
  return news
}

export const getPrevNews = async ({
  client,
  currentNews,
}: {
  client: NewtClient
  currentNews: News
}): Promise<{ slug: string } | null> => {
  const { createdAt } = currentNews._sys
  const news = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'news',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        lt: createdAt,
      },
      order: ['-_sys.createdAt'],
    },
  })
  return news
}

export const getNextNews = async ({
  client,
  currentNews,
}: {
  client: NewtClient
  currentNews: News
}): Promise<{ slug: string } | null> => {
  const { createdAt } = currentNews._sys
  const news = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'news',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        gt: createdAt,
      },
      order: ['_sys.createdAt'],
    },
  })
  return news
}

export const getSingle = async (client: NewtClient) => {
  const { items } = await client.getContents<News>({
    appUid: 'lawgue',
    modelUid: 'news',
    query: {
      select: [
        '_id',
        '_sys',
        'title',
        'newsCategory',
        'slug',
        'meta',
        'contents',
        'overwriteCreatedAt',
      ],
    },
  })
  return items
}

// 【コラム】記事一覧を取得
export const getColumn = async ({
  client,
  query,
  includeCategories = false,
  token = '',
  isPreview = false,
}: {
  client: NewtClient
  query?: GetContentsQuery
  includeCategories: boolean
  token?: string
  isPreview?: boolean
}): Promise<{
  column: Column[]
  total: number
  categories?: ColumnCategory[]
}> => {
  const { items: column, total } = await client.getContents<Column>({
    appUid: 'lawgue',
    modelUid: 'column',
    query: {
      depth: 2,
      ...query,
    },
  })

  let categories
  if (includeCategories) {
    // プレビューモードの場合は専用の関数を使用
    categories = isPreview
      ? await fetchCategoriesDataForPreview(token)
      : await fetchCategoriesData()
  }

  return {
    column,
    total,
    categories,
  }
}

async function fetchCategoriesData() {
  // カテゴリデータを取得
  const client = newtClient()
  const response = await client.getContents<ColumnCategory[]>({
    appUid: 'lawgue',
    modelUid: 'column-category',
    query: {},
  })
  return response.items
}

// 【コラム】カテゴリデータをプレビュー用に取得
async function fetchCategoriesDataForPreview(token: string) {
  const client = newtClient('api', token)
  const response = await client.getContents<ColumnCategory[]>({
    appUid: 'lawgue',
    modelUid: 'column-category',
    query: {},
  })
  return response.items
}

//【コラム】prevボタン（詳細ページ）
export const getPrevColumn = async (
  currentColumn: Column
): Promise<{ slug: string } | null> => {
  const client = newtClient()
  const { createdAt } = currentColumn._sys
  const column = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'column',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        lt: createdAt,
      },
      order: ['-_sys.createdAt'],
    },
  })
  return column
}

//【コラム】nextボタン（詳細ページ）
export const getNextColumn = async (
  currentColumn: Column
): Promise<{ slug: string } | null> => {
  const client = newtClient()
  const { createdAt } = currentColumn._sys
  const column = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'column',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        gt: createdAt,
      },
      order: ['_sys.createdAt'],
    },
  })
  return column
}

//【コラム】コラムカテゴリーからスラッグを取得
export const getColumnCategoryBySlug = async (slug: string) => {
  const client = newtClient()
  const res = await client.getFirstContent<ColumnCategory>({
    appUid: 'lawgue',
    modelUid: 'column-category',
    query: {
      slug,
    },
  })
  return res
}

// 【コラム】カテゴリIDからコラム一覧を取得・表示
export const getColumns = async (columnCategoryId: string) => {
  const client = newtClient()
  const { items } = await client.getContents<Column>({
    appUid: 'lawgue',
    modelUid: 'column',
    query: {
      columnCategory: {
        in: [columnCategoryId],
      },
    },
  })
  return items
}

//【コラム】詳細ページ表示
export const getColumnDetail = async () => {
  const client = newtClient()
  const { items } = await client.getContents<Column>({
    appUid: 'lawgue',
    modelUid: 'column',
    query: {
      select: [
        '_id',
        '_sys',
        'title',
        'columnCategory',
        'slug',
        'meta',
        'contents',
        'overwriteCreatedAt',
      ],
    },
  })
  return items
}

//【コラム】詳細ページサイドバーの新着コンテンツを最新順に取得
export const getLatestColumns = async () => {
  const client = newtClient()
  const { items } = await client.getContents<Column>({
    appUid: 'lawgue',
    modelUid: 'column',
    query: {
      order: ['-_sys.createdAt'],
      limit: 4,
    },
  })
  return items
}

//【コラム】サイドバーのカテゴリ別コンテンツ合計数取得
export const getColumnCountByCategory = async (category: ColumnCategory) => {
  const client = newtClient()
  const { total } = await client.getContents<ColumnCategory>({
    appUid: 'lawgue',
    modelUid: 'column',
    query: {
      columnCategory: category._id,
    },
  })
  return total
}

// 【メイン起業ロゴ】一覧を取得
export const getLogosList = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ logos: Logos[]; total: number }> => {
  const { items: logos, total } = await client.getContents<Logos>({
    appUid: 'lawgue',
    modelUid: 'logos',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    logos,
    total,
  }
}

// 【起業ロゴ】一覧を取得
export const getLogoList = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ logo: Logo[]; total: number }> => {
  const { items: logo, total } = await client.getContents<Logo>({
    appUid: 'lawgue',
    modelUid: 'logo',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    logo,
    total,
  }
}

//【セミナー】一覧ページ表示
export const getSeminarList = async ({
  client,
  query,
  token = '',
  isPreview = false,
}: {
  client: NewtClient
  query?: GetContentsQuery
  token?: string
  isPreview?: boolean
}): Promise<{ seminar: Seminar[]; total: number }> => {
  const { items: seminar, total } = await client.getContents<Seminar>({
    appUid: 'lawgue',
    modelUid: 'seminar',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    seminar,
    total,
  }
}

//【セミナー】詳細ページ表示
export const getSeminarDetail = async () => {
  const client = newtClient()
  const { items } = await client.getContents<Seminar>({
    appUid: 'lawgue',
    modelUid: 'seminar',
    query: {
      select: [
        '_id',
        '_sys',
        'title',
        'slug',
        'meta',
        'contents',
        'overwriteCreatedAt',
      ],
    },
  })
  return items
}

//【セミナー】prevボタン（詳細ページ）
export const getPrevSeminar = async (
  currentSeminar: Seminar
): Promise<{ slug: string } | null> => {
  const client = newtClient()
  const { createdAt } = currentSeminar._sys
  const seminar = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'seminar',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        lt: createdAt,
      },
      order: ['-_sys.createdAt'],
    },
  })
  return seminar
}

//【セミナー】nextボタン（詳細ページ）
export const getNextSeminar = async (
  currentSeminar: Seminar
): Promise<{ slug: string } | null> => {
  const client = newtClient()
  const { createdAt } = currentSeminar._sys
  const seminar = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'seminar',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        gt: createdAt,
      },
      order: ['_sys.createdAt'],
    },
  })
  return seminar
}

//【セミナー】その他のセミナー取得（詳細ページ）
export const getRelatedSeminar = async () => {
  const client = newtClient()
  const seminar = await client.getContents<Seminar>({
    appUid: 'lawgue',
    modelUid: 'seminar',
    query: {
      order: ['-_sys.createdAt'],
      limit: 3,
    },
  })
  return seminar
}

export const getPage = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}) => {
  const content = await client.getContents<{
    _id: string
    title: string
    slug: string
    description: string
    content: {
      type: 'MARKDOWN' | 'RICH_TEXT'
      data: string
      raw: { htmlText: string }
    }
    _sys: {
      createdAt: string
      updatedAt: string
    }
  }>({
    appUid: 'lawgue',
    modelUid: 'lawgue-pages',
    query: query ? { slug: query.slug } : undefined,
  })
  return content.items[0]
}

//【お役立ち資料】一覧ページ表示
export const getServiceList = async ({
  client,
  query,
  token = '',
}: {
  client: NewtClient
  query?: GetContentsQuery
  token?: string
}): Promise<{ service: Service[]; total: number }> => {
  const { items: service, total } = await client.getContents<Service>({
    appUid: 'lawgue',
    modelUid: 'service',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    service,
    total,
  }
}

//【お役立ち資料】詳細ページ表示
export const getServiceDetail = async () => {
  const client = newtClient()
  const { items } = await client.getContents<Service>({
    appUid: 'lawgue',
    modelUid: 'service',
    query: {
      select: [
        '_id',
        '_sys',
        'title',
        'slug',
        'meta',
        'contents',
        'overwriteCreatedAt',
      ],
    },
  })
  return items
}

//【お役立ち資料】prevボタン（詳細ページ）
export const getPrevService = async (
  currentService: Service
): Promise<{ slug: string } | null> => {
  const client = newtClient()
  const { createdAt } = currentService._sys
  const service = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'service',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        lt: createdAt,
      },
      order: ['-_sys.createdAt'],
    },
  })
  return service
}

//【お役立ち資料】nextボタン（詳細ページ）
export const getNextService = async (
  currentService: Service
): Promise<{ slug: string } | null> => {
  const client = newtClient()
  const { createdAt } = currentService._sys
  const service = await client.getFirstContent<{ slug: string }>({
    appUid: 'lawgue',
    modelUid: 'service',
    query: {
      select: ['slug'],
      '_sys.createdAt': {
        gt: createdAt,
      },
      order: ['_sys.createdAt'],
    },
  })
  return service
}

//【お役立ち資料】その他のお役立ち資料取得（詳細ページ）
export const getRelatedService = async () => {
  const client = newtClient()
  const service = await client.getContents<Service>({
    appUid: 'lawgue',
    modelUid: 'service',
    query: {
      order: ['-_sys.createdAt'],
      limit: 2,
    },
  })
  return service
}

// 【導入事例】記事一覧を取得
export const getVoice = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
  token?: string
  isPreview?: boolean
}): Promise<{
  voice: Voice[]
  total: number
}> => {
  const { items: voice, total } = await client.getContents<Voice>({
    appUid: 'lawgue',
    modelUid: 'voice',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    voice,
    total,
  }
}

//【導入事例】記事詳細を取得
export const getVoiceSingle = async (client: NewtClient) => {
  const { items } = await client.getContents<Voice>({
    appUid: 'lawgue',
    modelUid: 'voice',
    query: {
      select: [
        '_id',
        '_sys',
        'title',
        'companyImage',
        'slug',
        'meta',
        'contents',
        'overwriteCreatedAt',
      ],
    },
  })
  return items
}

//【導入事例】スラッグから記事詳細を取得
export const getVoiceItem = async ({
  client,
  slug,
}: {
  client: NewtClient
  slug: string
}): Promise<Voice | null> => {
  if (!slug) return null
  const voice = await client.getFirstContent<Voice>({
    appUid: 'lawgue',
    modelUid: 'voice',
    query: {
      depth: 2,
      slug,
    },
  })
  return voice
}

//【導入事例】関連記事の取得
export const getRelatedVoice = async () => {
  const client = newtClient()
  const voice = await client.getContents<Voice>({
    appUid: 'lawgue',
    modelUid: 'voice',
    query: {
      order: ['-_sys.customOrder'],
      limit: 3,
    },
  })
  return voice
}

// 事例-業種を取得
export const getIndustries = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ industries: Industries[] }> => {
  const { items: industries } = await client.getContents<Industries>({
    appUid: 'lawgue',
    modelUid: 'industries',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    industries,
  }
}

// 事例-導入部署
export const getDepartments = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ departments: Departments[] }> => {
  const { items: departments } = await client.getContents<Departments>({
    appUid: 'lawgue',
    modelUid: 'departments',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    departments,
  }
}

// 事例-企業規模
export const getScales = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ scales: Scales[] }> => {
  const { items: scales } = await client.getContents<Scales>({
    appUid: 'lawgue',
    modelUid: 'scales',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    scales,
  }
}

// 事例-文書類型
export const getDocumentTypes = async ({
  client,
  query,
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ documentTypes: DocumentTypes[] }> => {
  const { items: documentTypes } = await client.getContents<DocumentTypes>({
    appUid: 'lawgue',
    modelUid: 'documentTypes',
    query: {
      depth: 2,
      ...query,
    },
  })
  return {
    documentTypes,
  }
}

// 部署区分を取得
export const getDepartment = async ({
  client,
  query,
  token = '',
}: {
  client: NewtClient
  query?: GetContentsQuery
  token?: string
}): Promise<{
  department: Department[]
  total: number
}> => {
  const { items: department, total } = await client.getContents<Department>({
    appUid: 'lawgue',
    modelUid: 'department',
    query: {
      depth: 2,
      order: ['_sys.createdAt'],
      ...query,
    },
  })
  return {
    department,
    total,
  }
}

// LAWGUEを知ったきっかけ デフォルト値
const DEFAULT_OPPORTUNITY: Opportunity = {
  _id: 'default',
  _sys: {
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    customOrder: 0,
    raw: {
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      firstPublishedAt: new Date().toISOString(),
      publishedAt: new Date().toISOString(),
    },
  },
  label: '選択してください。',
  value: '',
}

// LAWGUEを知ったきっかけ オプション取得
export const getOpportunity = async ({
  client,
  query = {},
}: {
  client: NewtClient
  query?: GetContentsQuery
}): Promise<{ options: Opportunity[]; total: number }> => {
  const { items, total } = await client.getContents<Opportunity>({
    appUid: 'lawgue',
    modelUid: 'opportunity',
    query: {
      depth: 2,
      ...query,
    },
  })

  const options: Opportunity[] = [DEFAULT_OPPORTUNITY, ...items]

  return {
    options,
    total: total + 1,
  }
}
