import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')
export const Time = (
  props: { gmt: string } & React.ComponentPropsWithoutRef<'time'>
) => {
  const { gmt, ...attr } = props
  return (
    <time {...attr} dateTime={dayjs.utc(gmt).local().format('YYYY-MM-DD')}>
      {dayjs.utc(gmt).local().format('YYYY.MM.DD')}
    </time>
  )
}
