import Link from 'next/link'

export function Functions() {
  return (
    <>
      <section>
        <div className="text-center mx-auto pt-20 pb-20 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
          <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
            主な機能
          </h2>
          <p className="text-center maxsm:text-center text-base maxsm:text-base text-darken fontfeaturesettings mt-6">
            様々な機能があなたをサポートします
          </p>

          <div className="flex flex-wrap justify-center mt-14 maxsm:mt-6 column_m40">
            <div className="simple box false bg-white sec_inner_w293 relative py-10 px-8 border rounded-lg border-solid border-gray maxsm:w-full maxsm:py-6">
              <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-primary font-bold fontfeaturesettings">
                データベース化
              </h3>
              <p className="text-center maxsm:text-center text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2">
                自社文書を取り込むだけでナレッジを蓄積できます
              </p>
            </div>

            <div className="simple box false bg-white sec_inner_w293 relative py-10 px-8 border rounded-lg border-solid border-gray maxsm:w-full maxsm:py-6">
              <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-primary font-bold fontfeaturesettings">
                クラウド編集機能
              </h3>
              <p className="text-center maxsm:text-center text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2">
                クラウド上で複数人の編集が可能です
              </p>
            </div>
            <div className="simple box false bg-white sec_inner_w293 relative py-10 px-8 border rounded-lg border-solid border-gray maxsm:w-full maxsm:py-6">
              <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-primary font-bold fontfeaturesettings">
                AIレビューアシスト
              </h3>
              <p className="text-center maxsm:text-center text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2">
                自社雛形や過去文書との比較をAIがアシストします
              </p>
            </div>
            <div className="simple box false bg-white sec_inner_w293 relative py-10 px-8 border rounded-lg border-solid border-gray maxsm:w-full maxsm:py-6">
              <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-primary font-bold fontfeaturesettings">
                バージョン管理
              </h3>
              <p className="text-center maxsm:text-center text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2">
                クラウド上での管理により属人化を防止します
              </p>
            </div>

            <div className="simple box false bg-white sec_inner_w293 relative py-10 px-8 border rounded-lg border-solid border-gray maxsm:w-full maxsm:py-6">
              <img
                src="/images/img_icon_patent.png"
                className="absolute top-[-0.5rem] left-[-0.5rem] w-[82px] h-[80px] block"
                alt="特許技術"
                width={82}
                height={80}
              />
              <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-primary font-bold fontfeaturesettings">
                自動体裁補正
              </h3>
              <p className="text-center maxsm:text-center text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2">
                インデントや条番号のずれを自動で補正します
              </p>
            </div>

            <div className="simple box false bg-white sec_inner_w293 relative py-10 px-8 border rounded-lg border-solid border-gray maxsm:w-full maxsm:py-6">
              <img
                src="/images/img_icon_patent.png"
                className="absolute top-[-0.5rem] left-[-0.5rem] w-[82px] h-[80px] block"
                alt="特許技術"
                width={82}
                height={80}
              />
              <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-primary font-bold fontfeaturesettings">
                不足条項サジェスト
              </h3>
              <p className="text-center maxsm:text-center text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2">
                不足している条項をAIが
                <br />
                サジェストします
              </p>
            </div>
          </div>

          <Link
            href="/functions/"
            className="pagescroll block base_button linkbutton__medium  button__hidden undefined transition hover:text-secondary hover:border-secondary mt-16 maxsm:mt-8"
          >
            もっと見る
          </Link>
        </div>
      </section>
    </>
  )
}
