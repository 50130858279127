import Link from 'next/link'

export function Question() {
  return (
    <section>
      <div className="text-center mx-auto pt-40 pb-40 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          よくあるご質問
        </h2>

        <div className="mt-12 maxsm:mt-4 accordion_wrap">
          <div className="border rounded-lg border-solid border-gray bg-white">
            <div data-accordion-title="true" className="accordion__head">
              <div className="cursor-pointer flex items-center py-3 px-5">
                <div className="text-4xl font-robot font-medium text-primary">
                  Q
                </div>
                <div className="w-[93%] text-base text-left ml-5">
                  LAWGUEにWordファイルとの互換性はありますか？
                </div>
                <span className="w-[36px]">
                  <img
                    decoding="async"
                    src="/images/img_accordion_close.svg"
                    alt="Close"
                    className="w-full img_flag"
                  />
                </span>
              </div>
            </div>
            <div
              className="transition-all overflow-hidden max-h-0 accordion_body_0 accordion_body_wrap"
              aria-hidden="true"
            >
              <div className="accordion__body">
                <div className="flex items-start pt-3 pb-5 px-5">
                  <div className="text-4xl font-robot font-medium text-textblack">
                    A
                  </div>
                  <div className="text-base text-left ml-4">
                    Wordファイル(.docx)をインポートしてお使いいただけます。また、LAWGUE上で作成したファイルは同様にWordファイル(.docx)としてエクスポートすることができます。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[0.5rem] border rounded-lg border-solid border-gray bg-white">
            <div data-accordion-title="true" className="accordion__head">
              <div className="cursor-pointer flex items-center py-3 px-5">
                <div className="text-4xl font-robot font-medium text-primary">
                  Q
                </div>
                <div className="w-[93%] text-base text-left ml-5">
                  LAWGUEはどのような環境で利用できますか？
                </div>
                <span className="w-[36px]">
                  <img
                    decoding="async"
                    src="/images/img_accordion_close.svg"
                    alt="Close"
                    className="w-full img_flag"
                  />
                </span>
              </div>
            </div>
            <div
              className="transition-all overflow-hidden max-h-0 accordion_body_0 accordion_body_wrap"
              aria-hidden="true"
            >
              <div className="accordion__body">
                <div className="flex items-start pt-3 pb-5 px-5">
                  <div className="text-4xl font-robot font-medium text-textblack">
                    A
                  </div>
                  <div className="text-base text-left ml-4">
                    インターネット環境下でご利用いただけます。（対応ブラウザはGoogle
                    Chromeを推奨）時間・場所問わずPCのブラウザ上でご利用いただけます。インストール等は必要なく、ブラウザ画面上でログインしていただければご利用可能です。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[0.5rem] border rounded-lg border-solid border-gray bg-white">
            <div data-accordion-title="true" className="accordion__head">
              <div className="cursor-pointer flex items-center py-3 px-5">
                <div className="text-4xl font-robot font-medium text-primary">
                  Q
                </div>
                <div className="w-[93%] text-base text-left ml-5">
                  LAWGUEは契約書以外にも使えますか？
                </div>
                <span className="w-[36px]">
                  <img
                    decoding="async"
                    src="/images/img_accordion_close.svg"
                    alt="Close"
                    className="w-full img_flag"
                  />
                </span>
              </div>
            </div>
            <div
              className="transition-all overflow-hidden max-h-0 accordion_body_0 accordion_body_wrap"
              aria-hidden="true"
            >
              <div className="accordion__body">
                <div className="flex items-start pt-3 pb-5 px-5">
                  <div className="text-4xl font-robot font-medium text-textblack">
                    A
                  </div>
                  <div className="text-base text-left ml-4">
                    使えます。契約書以外にも、規程類や仕様書、その他特許文書やIR文書、議事録、プレスリリースの文案作成等でご活用いただけます。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[0.5rem] border rounded-lg border-solid border-gray bg-white">
            <div data-accordion-title="true" className="accordion__head">
              <div className="cursor-pointer flex items-center py-3 px-5">
                <div className="text-4xl font-robot font-medium text-primary">
                  Q
                </div>
                <div className="w-[93%] text-base text-left ml-5">
                  一般的なAI契約審査サービスとの違いを教えて下さい。
                </div>
                <span className="w-[36px]">
                  <img
                    decoding="async"
                    src="/images/img_accordion_close.svg"
                    alt="Close"
                    className="w-full img_flag"
                  />
                </span>
              </div>
            </div>
            <div
              className="transition-all overflow-hidden max-h-0 accordion_body_0 accordion_body_wrap"
              aria-hidden="true"
            >
              <div className="accordion__body">
                <div className="flex items-start pt-3 pb-5 px-5">
                  <div className="text-4xl font-robot font-medium text-textblack">
                    A
                  </div>
                  <div className="text-base text-left ml-4">
                    レビューの観点では、契約書の類型によらずに（かつ契約書以外の文書であっても）ご利用いただける点や、「自社の過去文書との比較による」リスク検知や不足条項の自動検出が可能な点が異なります。契約書の類型を問わない条項単位の不足条項のサジェスト機能はFRAIM株式会社の保有する特許技術で実現しており、弊社のみが提供可能なものとなります。クラウド上での編集機能ではインデントやナンバリングの自動追従等の実現により、従来のWordを前提とした編集作業では到達できていない作業時間の短縮を実現しております。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[0.5rem] border rounded-lg border-solid border-gray bg-white">
            <div data-accordion-title="true" className="accordion__head">
              <div className="cursor-pointer flex items-center py-3 px-5">
                <div className="text-4xl font-robot font-medium text-primary">
                  Q
                </div>
                <div className="w-[93%] text-base text-left ml-5">
                  導入を検討する場合のフローについて教えてください
                </div>
                <span className="w-[36px]">
                  <img
                    decoding="async"
                    src="/images/img_accordion_close.svg"
                    alt="Close"
                    className="w-full img_flag"
                  />
                </span>
              </div>
            </div>
            <div
              className="transition-all overflow-hidden max-h-0 accordion_body_0 accordion_body_wrap"
              aria-hidden="true"
            >
              <div className="accordion__body">
                <div className="flex items-start pt-3 pb-5 px-5">
                  <div className="text-4xl font-robot font-medium text-textblack">
                    A
                  </div>
                  <div className="text-base text-left ml-4">
                    まずは
                    <Link href="/contact" className="text-primary">
                      お問合せより
                    </Link>
                    お気軽にご相談ください。
                    <br />
                    各ユーザー様が課題に思われていらっしゃる業務についてカスタマースタッフがお話をお伺いいたします（2営業日以内）。また必要に応じて、セールススタッフより詳細のご提案をいたします。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
