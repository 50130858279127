import type { Logo } from '@/types/newt/logo'

export function Logo({ logo }: { logo: Logo }) {
  return (
    <div className="w-[30.734%] sm:w-[13.4%]">
      <img
        src={logo.logo.src}
        alt={logo.title}
        width={logo.logo.width}
        height={logo.logo.height}
        decoding="async"
        className="w-full h-auto"
      />
    </div>
  )
}
