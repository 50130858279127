import Link from 'next/link'
import React from 'react'

const SolutionBox = ({
  imageSrc,
  title,
  description,
  link,
  isExternal = false,
}) => (
  <div className="simple box bg-white sec_inner_w293 relative py-10 px-8 border rounded-lg border-solid border-gray maxsm:w-full maxsm:py-6">
    <div className="flex justify-center flex-col-reverse items-center">
      <div></div>
      <img decoding="async" src={imageSrc} alt="" className="maxsm:h-full" />
    </div>

    <h3 className="text-center maxsm:text-center text-lg maxsm:text-lg text-primary font-bold fontfeaturesettings mt-2 maxsm:mt-0">
      {title}
    </h3>

    <p className="text-center maxsm:text-center text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3">
      {description}
    </p>

    {isExternal ? (
      <p className="pagescroll base_button_d1link button__hidden transition hover:text-secondary hover:border-secondary mt-4 text-center">
        <a href={link} target="_blank" rel="noreferrer noopener">
          詳しく見る
        </a>
      </p>
    ) : (
      <Link
        href={link}
        className="pagescroll block base_button linkbutton__large button__hidden transition hover:text-secondary hover:border-secondary mt-4 text-center"
      >
        詳しく見る
      </Link>
    )}
  </div>
)

export const Solution = () => {
  const solutions = [
    {
      imageSrc: '/images/img_solution01.png',
      title: '契約ナレッジマネジメント',
      description: (
        <>
          契約書業務の時間を大幅に
          <br />
          削減します
        </>
      ),
      link: '/contract',
    },
    {
      imageSrc: '/images/img_solution02.png',
      title: '規程・マニュアル管理',
      description: (
        <>
          規程管理をクラウドで
          <br />
          効率化できます
        </>
      ),
      link: '/regulation',
    },
    {
      imageSrc: '/images/img_solution03.png',
      title: '法令改正対応',
      description: (
        <>
          最新の法改正情報を受け取り
          <br />
          法改正に迅速に対応します
        </>
      ),
      link: '/law',
    },
    {
      imageSrc: '/images/img_solution04.png',
      title: '英文契約書レビュー',
      description: '対訳辞書登録で正確な翻訳を実現します',
      link: '/translation',
    },
    {
      imageSrc: '/images/img_solution05.png',
      title: 'D1-LAWGUE',
      description: '次世代クラウドエディタで行政文書のDXを推進します',
      link: 'https://d1.lawgue.com/',
      isExternal: true,
    },
  ]

  return (
    <section>
      <div className="text-center mx-auto pt-40 pb-20 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          ソリューション
        </h2>

        <p className="text-center maxsm:text-center text-base maxsm:text-base text-darken fontfeaturesettings mt-6 leading-7 maxsm:leading-7">
          LAWGUEを使用するとドキュメントを数分で作成し、驚くほど仕事を効率化することができます。
          <br />
          便利な通知やコラボレーション、改善のヒントとともに、様々な場面で活躍します。
        </p>

        <div className="flex flex-wrap justify-center mt-14 maxsm:mt-6 column_m40">
          {solutions.map((solution, index) => (
            <SolutionBox key={index} {...solution} />
          ))}
        </div>
      </div>
    </section>
  )
}
