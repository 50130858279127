import Link from 'next/link'

export function Security() {
  return (
    <section>
      <div className="text-center mx-auto pt-40 pb-40 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          セキュリティ
        </h2>

        <p className="text-center maxsm:text-center text-base maxsm:text-base text-darken fontfeaturesettings mt-6">
          安心してご利用いただくために
        </p>

        <div className="flex flex-wrap items-sketch justify-center mt-14 maxsm:mt-6 column60 column60_col3">
          <div className="simple box false max-w-[448px] maxtb03:max-w-[47%] maxsm:w-full md:w-1/2">
            <div className="flex justify-center flex-col-reverse items-center">
              <img
                src="/images/img_security01-v2.png"
                alt="安心のクラウド基盤"
                width={438}
                height={140}
              />
            </div>
            <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-textblack font-bold fontfeaturesettings mt-2 maxsm:mt-0">
              安心のクラウド基盤
            </h3>
            <p className="text-left text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2 leading-7">
              AWS上にサービスを構築し、TLS1.2による通信の暗号化、AES-256によるデータ暗号化に加え、各種高水準のセキュリティ対策を施しています。
            </p>
          </div>

          <div className="simple box false max-w-[448px] maxtb03:max-w-[47%] maxsm:w-full">
            <div className="flex justify-center flex-col-reverse items-center">
              <img
                src="/images/img_security02-v2.png"
                alt="第三者機関の診断"
                width={438}
                height={140}
              />
            </div>
            <h3 className="text-center maxsm:text-center text-2xl maxsm:text-base text-textblack font-bold fontfeaturesettings mt-2 maxsm:mt-0">
              第三者機関の診断
            </h3>
            <p className="text-left text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2 leading-7">
              国内有数の診断実績を誇る第三者機関の脆弱性診断実施による侵入対策を定期的に実施しています。
            </p>
          </div>
        </div>
        <div className="mx-auto pt-14 maxsm:pt-10">
          <h2 className="text-center maxsm:text-center mb-9 maxsm:mb-2 text-3xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
            情報管理について
          </h2>
          <div className="maxsm:w-full flex flex-row maxsm:flex-col justify-center items-center py-8 px-6 border-t border-b border-gray maxsm:border-none maxsm:p-0">
            <div className="flex justify-center items-center">
              <img
                src="/images/img_security04-v2.png"
                alt="ISMS(ISO27001)取得"
                width={240}
                height={138}
                className="w-8/12 xs:w-9/12 sm:w-auto"
              />
            </div>
            <div className="flex flex-col justify-center ml-16 maxsm:ml-0">
              <h3 className="text-left maxsm:text-center text-2xl maxsm:text-base text-textblack font-bold fontfeaturesettings maxsm:mt-0">
                ISMS(ISO27001)取得
              </h3>
              <p className="text-left text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2 leading-7">
                LAWGUEの運営元であるFRAIM株式会社は
                <br className="hidden xs:block" />
                情報セキュリティマネジメントシステム(ISMS)の認証を取得しています。
                <br className="hidden xs:block sm:hidden" />
                また、プライバシーマークも取得しており、安心の情報管理をしています。
              </p>
            </div>
          </div>
        </div>

        <Link
          href="/security/"
          className="pagescroll block base_button linkbutton__xlarge button__hidden button__nonefull transition hover:text-secondary hover:border-secondary mt-16 maxsm:mt-8"
        >
          もっと見る
        </Link>
      </div>
    </section>
  )
}
