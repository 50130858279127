import { useState, useEffect } from 'react'

const useAccordion = () => {
  useEffect(() => {
    const accordion_reset = () => {
      const all_accordion_body_wrap = document.querySelectorAll(
        '.accordion_body_wrap'
      )
      const all_img_flag = document.querySelectorAll('.img_flag')

      all_accordion_body_wrap.forEach((v: any, i, a) => {
        v.style.maxHeight = 0 + 'px'
        v.setAttribute('aria-hidden', false)
        all_img_flag[i].setAttribute('src', '/images/img_accordion_close.svg')
        if (v.classList.contains('add')) {
          v.classList.remove('add')
        }
      })
    }

    const handleClick = (e: any) => {
      const elm = e.currentTarget.nextElementSibling

      if (elm.classList.contains('add')) {
        elm.classList.remove('add')
        elm.setAttribute('aria-hidden', false)
        elm.style.maxHeight = 0 + 'px'
        e.currentTarget
          .querySelector('.img_flag')
          .setAttribute('src', '/images/img_accordion_close.svg')
      } else {
        accordion_reset()

        const h = elm.querySelector('.accordion__body').clientHeight
        elm.classList.add('add')
        elm.setAttribute('aria-hidden', true)
        elm.style.maxHeight = h + 'px'
        e.currentTarget
          .querySelector('.img_flag')
          .setAttribute('src', '/images/img_accordion_open.svg')
      }
    }

    const accordion_target = document.querySelectorAll('[data-accordion-title]')
    accordion_target.forEach((v) => {
      v.addEventListener('click', handleClick, false)
    })

    return () => {
      accordion_target.forEach((v) => {
        v.removeEventListener('click', handleClick)
      })
    }
  }, [])
}

export default useAccordion
