'use client'

import React, { useEffect } from 'react'
import useAccordion from '@/hooks/useAccordion'

import { Request } from '@/app/components/templates/non-block/Request'
import { Functions } from '@/app/components/templates/non-block/Functions'
import { Security } from '@/app/components/templates/non-block/Security'
import { Question } from '@/app/components/templates/non-block/Question'
import { Contact } from '@/components/parts/contact'
import { Logo } from '@/app/components/templates/non-block/Logo'
import Link from 'next/link'
import { Reason } from '@/app/components/templates/non-block/Reason'
import { Solution } from '@/app/components/templates/non-block/Solution'
import { Resolve } from '@/app/components/templates/non-block/Resolve'
import { Issue } from '@/app/components/templates/non-block/Issue'
import { Video } from '@/app/components/templates/non-block/Video'
import Visual from '@/app/components/templates/non-block/Visual'
import { NewsList } from '@/app/components/newt/templates/NewsList'
import { VoiceList } from '@/app/components/newt/templates/VoiceList'

export default function Top({ news, logo, voice, logos }) {
  useAccordion()
  useEffect(() => {}, [])

  return (
    <>
      <Visual logos={logos} />
      <Video />
      <Issue />
      <Resolve />
      <Request position={'resolve'} />
      <Solution />
      <Reason />
      <section className="text-center mx-auto pt-40 pb-40 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          導入事例
        </h2>
        <ul className="flex flex-col sm:grid sm:grid-cols-3 gap-y-[30px] gap-x-[60px] mt-[24px] sm:mt-[40px] transition duration-0 ease-in text-left">
          {voice.map((voiceItem) => (
            <VoiceList key={voiceItem._id} voice={voiceItem} />
          ))}
        </ul>
        <Link
          href="/voice"
          className="block base_button linkbutton__medium button__hidden undefined transition hover:text-secondary hover:border-secondary mt-16 maxsm:mt-8"
        >
          もっと見る
        </Link>
      </section>
      <section className="text-center mx-auto pt-40 pb-44 bg-white maxsm:w-full maxsm:pb-20 maxsm:pt-10 maxsm:px-6">
        <h2 className="title text-center text-textblack text-base md:text-2xl font-bold">
          他にも、数多くの企業様にご利用いただいています
        </h2>
        <div className="mt-16 maxsm:mt-8">
          <div className="m-auto max-w-[1176px] flex flex-wrap justify-start items-center gap-x-[3.9%] gap-y-[40px] sm:gap-y-[42px] px-[0.25rem] sm:px-[3rem]">
            {logo.map((logoItem) => (
              <Logo key={logoItem._id} logo={logoItem} />
            ))}
          </div>
        </div>
      </section>
      <Request position={'voice'} />
      <Functions />
      <Security />
      <Question />
      <section className="text-center mx-auto pt-40 pb-40 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          お知らせ
        </h2>
        <div className="mt-12 maxsm:mt-4">
          {news.map((newsItem) => (
            <NewsList key={newsItem._id} news={newsItem} />
          ))}
        </div>
      </section>
      <Contact bg="bg-lawguebase" />
    </>
  )
}
