import React from 'react'

const IssueBox = ({ title, description, imageSrc }) => (
  <div className="simple box bg-[transparent] column_w280 maxsm:w-full">
    <div className="flex justify-center sm:flex-col-reverse maxsm:flex-wrap maxsm:justify-start">
      <div className="maxsm:w-1/2">
        <h3 className="text-center maxsm:text-left text-2xl maxsm:text-base text-textblack font-bold fontfeaturesettings">
          {title}
        </h3>
        <p className="text-center maxsm:text-left text-base maxsm:text-sm text-textblack fontfeaturesettings mt-3 maxsm:mt-2">
          {description}
        </p>
      </div>
      <img
        decoding="async"
        src={imageSrc}
        alt=""
        className="maxsm:w-1/2 maxsm:h-full"
      />
    </div>
  </div>
)

export const Issue = () => {
  const issues = [
    {
      title: (
        <>
          気づけば1日中
          <br />
          文書探し
        </>
      ),
      description: '探している文書がいつまでたっても見つからない',
      imageSrc: '/images/img_quest01.png',
    },
    {
      title: (
        <>
          「誰が」「なぜ」が
          <br />
          わからない
        </>
      ),
      description: '作成過程のコメントや修正履歴がブラックボックス化',
      imageSrc: '/images/img_quest05.png',
    },
    {
      title: (
        <>
          ひとつひとつ確認して
          <br />
          体裁を修正
        </>
      ),
      description:
        '完成したと思っても、フォーマット調整が作成以上に時間がかかる',
      imageSrc: '/images/img_quest06.png',
    },
  ]

  return (
    <section>
      <div className="text-center mx-auto pt-20 pb-20 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          文書業務でこんなことは
          <br className="sm:hidden" />
          ありませんか？
        </h2>
        <div className="flex flex-wrap items-sketch justify-center mt-14 maxsm:mt-6 column60 column60_col3">
          {issues.map((issue, index) => (
            <IssueBox key={index} {...issue} />
          ))}
        </div>
      </div>
      <div className="img_arrow">
        <img
          decoding="async"
          src="/images/img_sec_arrow.svg"
          alt="Arrow"
          className="w-full"
        />
      </div>
    </section>
  )
}
