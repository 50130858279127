import type { Logos } from '@/types/newt/logos'

export function MainLogos({ logos }: { logos: Logos }) {
  return (
    <div className="">
      <img
        src={logos.logos.src}
        alt={logos.title}
        width={logos.logos.width}
        height={logos.logos.height}
        decoding="async"
        className="w-full h-auto"
      />
    </div>
  )
}
