import Link from 'next/link'
import { useCLickEvent } from '@/hooks/use-tracker'

export function Request({ position }: { position: string }) {
  const { pushGa4ClickEvent } = useCLickEvent()
  return (
    <section className="bg-lawguebase">
      <div className="text-center sec_inner_w60 mx-auto py-20 maxsm:w-full maxsm:pb-10 maxsm:pt-10">
        <div className="relative">
          <h2 className="text-center maxsm:text-center text-2xl maxsm:text-base text-textblack font-bold fontfeaturesettings">
            さあ、新しいドキュメント作成をはじめましょう
          </h2>

          <Link
            href="/request"
            className="block base_button linkbutton__xlarge button__visible undefined transition hover:bg-secondary hover:border-secondary mt-10 maxsm:mt-4"
            onClick={() => {
              pushGa4ClickEvent({
                eventCategory: 'request',
                eventUi: `top-${position}-after`,
              })
            }}
          >
            資料をダウンロード
          </Link>
          <img
            src="/images/img_info.svg"
            alt="資料をダウンロード"
            className="img_info"
            width={320}
            height={213}
          />
        </div>
      </div>
    </section>
  )
}
